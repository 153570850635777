var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "truncate"
  }, [_c('h2', {
    staticClass: "mb-25 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.statistic) + " ")]), _c('span', [_vm._v(_vm._s(_vm.statisticTitle))])]), _c('b-avatar', {
    attrs: {
      "variant": "light-".concat(_vm.color),
      "size": "45"
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": _vm.icon
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }